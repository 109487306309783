import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import { mainStore, prospexStore, parceriaCupomStore } from '@/store';
let PerdaComercialComponent = class PerdaComercialComponent extends Vue {
    constructor() {
        super(...arguments);
        this.showDialog = false;
        this.motivoPerdaId = 0;
        this.descricaoPerda = '';
        this.perdaIncluida = false;
        this.dataPerda = null;
        this.titulo = '';
    }
    async salvarPerda() {
        this.dataPerda = new Date();
        if (this.propTipo == 'prospex') {
            await prospexStore.updateProspex({
                // @ts-ignore
                motivo_perda_id: this.motivoPerdaId,
                motivo_perda_descricao: this.descricaoPerda,
                data_perda: this.dataPerda,
                id: this.propId,
            });
            this.closePerdaDialog();
        }
        else if (this.propTipo == 'parceria_cupom') {
            //
            await parceriaCupomStore.updateParceriaCupom({
                // @ts-ignore
                motivo_perda_id: this.motivoPerdaId,
                motivo_perda_descricao: this.descricaoPerda,
                data_perda: this.dataPerda,
                id: this.propId,
            });
            this.closePerdaDialog();
        }
        this.perdaIncluida = true;
        this.$emit('input', this.perdaIncluida);
    }
    async showPerdaDialog() {
        this.showDialog = true;
    }
    async closePerdaDialog() {
        this.showDialog = false;
    }
    setProps() {
        this.motivoPerdaId = this.propMotivoPerdaId;
        this.dataPerda = this.propDataPerda;
        this.descricaoPerda = this.propDescricaoPerda;
        if (this.motivoPerdaId) {
            this.perdaIncluida = true;
            this.$emit('input', this.perdaIncluida);
        }
    }
    mounted() {
        if (this.propTipo == 'prospex') {
            this.titulo = mainStore.userProfile.company.escola_id == 2 ? 'check-in' : 'prospex';
        }
        else if (this.propTipo == 'parceria_cupom') {
            this.titulo = 'cupom';
        }
        if (this.propMotivoPerdaId) {
            this.setProps();
        }
        else {
            setTimeout(() => {
                this.setProps();
            }, 500);
        }
    }
};
__decorate([
    Prop({ default: '' })
], PerdaComercialComponent.prototype, "propTipo", void 0);
__decorate([
    Prop({ default: 0 })
], PerdaComercialComponent.prototype, "propId", void 0);
__decorate([
    Prop({ default: '' })
], PerdaComercialComponent.prototype, "propDescricaoPerda", void 0);
__decorate([
    Prop({ default: 0 })
], PerdaComercialComponent.prototype, "propMotivoPerdaId", void 0);
__decorate([
    Prop({ default: null })
], PerdaComercialComponent.prototype, "propDataPerda", void 0);
__decorate([
    Prop({ default: false })
], PerdaComercialComponent.prototype, "propDisable", void 0);
__decorate([
    Prop()
], PerdaComercialComponent.prototype, "value", void 0);
PerdaComercialComponent = __decorate([
    Component({
        components: {}
    })
], PerdaComercialComponent);
export default PerdaComercialComponent;
