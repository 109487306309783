export function newParceriaCupom() {
    return {
        nome: '',
        telefone: '',
        email: '',
        parceria_id: 0,
        status_id: 1,
        cda_id: null,
        idade: null,
    };
}
