import { __decorate } from "tslib";
// Watch
import { Component, Vue } from 'vue-property-decorator';
import { parceriaCupomStore, parceriaStore } from '@/store';
import SaveButton from '@/components/SaveButton.vue';
import SearchEntidadeComponent from '@/components/SearchEntidadeComponent.vue';
import OcorrenciaComponent from '@/components/OcorrenciaComponent.vue';
import ParceriaCupomHistoricoComponent from '@/components/ParceriaCupomHistoricoComponent.vue';
import PerdaComercialComponent from '@/components/PerdaComercialComponent.vue';
import _ from 'lodash';
import { ValidationObserver, ValidationProvider, Validator, } from 'vee-validate';
import { newParceriaCupom } from '@/interfaces/parceria_cupom';
Validator.extend('required', {
    getMessage: (field) => `${field} deve ser preenchido!`,
    validate: async (value) => !!value,
});
let Edit = class Edit extends Vue {
    constructor() {
        super(...arguments);
        this.id = null;
        this.entityObject = null;
        this.search = '';
        this.selectedItem = { id: 0 };
        this.jsonData = '';
        this.tab = 0;
    }
    get parcerias() {
        return parceriaStore.parcerias;
    }
    get jsonString() {
        return JSON.stringify(this.entityObject.dados_adicionais, null, 2);
    }
    async submit() {
        const isValid = await this.$refs.observer.validate();
        if (!isValid) {
            // @ts-ignore
            this.$refs.saveButton.changeLoadingStatus(false);
            return;
        }
        if (this.id) {
            this.entityObject.parceria_id = this.selectedItem.id;
            delete this.entityObject.status_id;
            const result = await parceriaCupomStore.updateParceriaCupom(this.entityObject);
        }
        else {
            this.entityObject.parceria_id = this.selectedItem.id;
            const result = await parceriaCupomStore.createParceriaCupom(this.entityObject);
            this.id = result;
            this.entityObject.id = result;
        }
        // @ts-ignore
        await this.$refs.saveButton.changeLoadingStatus(false);
        await this.$router.push({ name: 'main-cupons' });
    }
    created() {
        this.entityObject = newParceriaCupom();
    }
    async mounted() {
        await parceriaStore.getParcerias();
        if (this.$router.currentRoute.params.id) {
            this.id = this.$router.currentRoute.params.id;
            this.entityObject = await parceriaCupomStore.getParceriaCupomByParceriaId(parseInt(this.$router.currentRoute.params.id, 0));
            this.selectedItem = _.find(this.parcerias, (item) => item.id === this.entityObject.parceria_id);
        }
        else {
            this.entityObject = newParceriaCupom();
        }
    }
};
Edit = __decorate([
    Component({
        components: {
            ValidationObserver,
            ValidationProvider,
            ParceriaCupomHistoricoComponent,
            OcorrenciaComponent,
            SearchEntidadeComponent,
            SaveButton,
            PerdaComercialComponent,
        },
    })
], Edit);
export default Edit;
