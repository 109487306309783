import { __decorate } from "tslib";
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
// import { filtroPorTabelaStore } from '@/store';
import VDataTableCheckbox from '@/components/VDataTableCheckbox.vue';
import { analyticsStore } from '@/utils/store-accessor';
import _ from 'lodash';
let GenericListTotalCard = class GenericListTotalCard extends Vue {
    constructor() {
        super(...arguments);
        this.timer = 0;
        this.oldvalue = [];
        this.myWatcher = null;
        this.checkAll = true;
    }
    get filtroHeaders() {
        let headers = [];
        if (!!this.propShowCheckbox) {
            headers = [
                { text: '', value: 'selected', width: '1px', sortable: false },
            ];
        }
        if (this.propItems) {
            const campos = this.propItems.items[0];
            if (campos) {
                if (Object.keys(campos).includes('label')) {
                    headers.push({ text: 'Nome', value: 'label' });
                }
                if (Object.keys(campos).includes('value')) {
                    headers.push({ text: 'Quantidade', value: 'value' });
                }
                Object.keys(campos).forEach((item) => {
                    if (!['selected', 'value', 'id', 'label'].includes(item) &&
                        !item.toLowerCase().includes('valor') &&
                        !item.toLowerCase().includes('temp')) {
                        headers.push({
                            text: item,
                            value: item,
                        });
                    }
                    if (item.toLowerCase().includes('temp')) {
                        headers.push({
                            text: campos[item],
                            value: item,
                        });
                    }
                });
            }
        }
        return headers;
    }
    select() {
        analyticsStore.executeSearchItems();
    }
    sumSelectedField(items, key) {
        const selectedItems = items.filter((item) => item.selected);
        // @ts-ignore
        return this.sumField(selectedItems, key);
    }
    get loading() {
        return analyticsStore.loading;
    }
    async watchLoading(val) {
        if (val) {
            // destroi o watch
            this.myWatcher();
        }
        else {
            // cria o watch
            this.criaWatch();
        }
    }
    async onCheckAll(val) {
        _.forEach(this.propItems.items, (item) => {
            item.selected = val;
        });
    }
    criaWatch() {
        // esse mecanismo é necessário porque cada totalizador da tela estava
        // disparando uma requisicao quando o totals era atualizado
        // todos entendiam que algo tinha sido mudado e deveriam atualizar...
        this.myWatcher = this.$watch('propItems.items', (val) => {
            if (!_.isEqual(val, this.oldvalue)) {
                clearTimeout(this.timer);
                // @ts-ignore
                this.timer = setTimeout(async () => {
                    analyticsStore.executeSearchItems();
                }, 800);
            }
            this.oldvalue = _.cloneDeep(val);
        }, {
            deep: true,
        });
    }
    mounted() {
        this.oldvalue = _.cloneDeep(this.propItems.items);
        this.criaWatch();
    }
};
__decorate([
    Prop({ type: String, default: '' })
], GenericListTotalCard.prototype, "propTitle", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], GenericListTotalCard.prototype, "propFixedHeaders", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], GenericListTotalCard.prototype, "propAppend", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], GenericListTotalCard.prototype, "propShowCheckbox", void 0);
__decorate([
    Prop({ type: String, default: '' })
], GenericListTotalCard.prototype, "propHeight", void 0);
__decorate([
    Prop({ type: Object })
], GenericListTotalCard.prototype, "propItems", void 0);
__decorate([
    Watch('loading')
], GenericListTotalCard.prototype, "watchLoading", null);
__decorate([
    Watch('checkAll')
], GenericListTotalCard.prototype, "onCheckAll", null);
GenericListTotalCard = __decorate([
    Component({
        components: {
            VDataTableCheckbox,
        },
    })
], GenericListTotalCard);
export default GenericListTotalCard;
