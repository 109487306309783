import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import { userToolStore } from '@/store';
let CaixaAbrirComponent = class CaixaAbrirComponent extends Vue {
    get getUserToolsVisualizar() {
        let retorno = false;
        // @ts-ignore
        if (userToolStore.userToolsIndexed.Aluno) {
            // @ts-ignore
            if (userToolStore.userToolsIndexed.Aluno[0].allow_view) {
                retorno = true;
            }
        }
        return retorno;
    }
    async abrirCadastro() {
        let entidade_tipo = this.propEntidadeTipo == 'cupom' ? `main-cupons-edit` : `main-${this.propEntidadeTipo}s-edit`;
        entidade_tipo = this.propEntidadeTipo == 'reposicao' ? `main-reposicoes-edit` : entidade_tipo;
        const rota = this.$router.resolve({
            name: entidade_tipo,
            params: { id: this.propEntidadeId },
        });
        window.open(rota.href, '_blank');
    }
};
__decorate([
    Prop({ type: Number })
], CaixaAbrirComponent.prototype, "propEntidadeId", void 0);
__decorate([
    Prop({ type: String })
], CaixaAbrirComponent.prototype, "propEntidadeNome", void 0);
__decorate([
    Prop({ type: String })
], CaixaAbrirComponent.prototype, "propEntidadeTipo", void 0);
CaixaAbrirComponent = __decorate([
    Component
], CaixaAbrirComponent);
export default CaixaAbrirComponent;
