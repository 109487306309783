import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import UploadFilesComponent from '@/components/UploadFilesComponent.vue';
import _ from 'lodash';
import ClickableCard from '@/components/ClickableCard.vue';
import DateFieldComponent from '@/components/DateFieldComponent.vue';
import SearchEntidadeComponent from '@/components/SearchEntidadeComponent.vue';
import { ocorrenciaStore, departamentoStore, objecaoStore } from '@/store';
import { ValidationObserver, ValidationProvider, Validator, } from 'vee-validate';
Validator.extend('timeValidationRules', {
    validate: (value) => {
        const regex = /^([01]\d|2[0-3]):([0-5]\d)$/;
        return regex.test(value);
    },
    getMessage: (field) => 'O campo de hora deve estar no formato HH:MM',
});
let CriarOcorrenciaComponent = class CriarOcorrenciaComponent extends Vue {
    constructor() {
        super(...arguments);
        this.tarefaHeaders = [
            { text: 'Usuário responsável', value: 'responsavel_nome' },
            { text: 'Tarefa agendada para', value: 'data' },
            { text: 'Descrição', value: 'descricao' },
            { text: 'Reunião', value: 'reuniao_tipo_id' },
            { text: '', value: 'actions' },
        ];
        this.loadingGerandoOcorrencia = false;
        this.e1 = 1;
        this.tarefas = [];
        this.desejaGerarTarefaSelected = null;
        this.desejaGerarReuniaoSelect = null;
        this.ocorrenciaEntidadeSelected = null;
        this.ocorrenciaTarefaData = null;
        this.ocorrenciaTarefaHora = null;
        this.ocorrenciasBy = [];
        this.ocorrenciaItems = [];
        this.modoCriarOcorrencia = false;
        this.origemSelected = [];
        this.departamentoSelected = [];
        this.assuntoSelected = [];
        this.objecaoSelected = [];
        this.ocorrenciaUploadFiles = [];
        this.ocorrenciaObservacaoTarefa = null;
        this.ocorrenciaObservacao = '';
        this.tarefaDescricao = '';
        this.ocorrenciaReuniaoSelected = null;
    }
    get ocorrenciaOrigemOptions() {
        return [
            { id: 1, nome: 'Ligação' },
            { id: 2, nome: 'Mensagem' },
            { id: 3, nome: 'Video' },
            { id: 4, nome: 'Presencial' },
            { id: 5, nome: 'Informação' },
        ];
    }
    get ocorrenciaReuniaoOptions() {
        return [
            { id: 1, nome: 'Outros' },
            { id: 2, nome: 'Reunião financeira' },
            { id: 3, nome: 'Reunião pedagógica' },
            { id: 4, nome: 'Reunião de rendimento' },
            { id: 5, nome: 'Reunião troca de livro' },
            { id: 6, nome: 'Reunião rematrícula' },
        ];
    }
    onchangeOrigemSelecetd(val) {
        if (val.length <= 0) {
            return;
        }
        this.assuntoSelected = [];
        this.departamentoSelected = [];
        this.objecaoSelected = [];
        this.assuntoSelected = [];
        this.departamentoSelected = [];
        this.e1++;
    }
    onChangeObjecaoSelected(val) {
        if (val.length <= 0) {
            return;
        }
        this.e1++;
    }
    onChangeAssuntoSelected(val) {
        if (val.length <= 0) {
            return;
        }
        this.objecaoSelected = [];
        this.e1++;
    }
    onChangeDepartamentoSelected(val) {
        if (val.length <= 0) {
            return;
        }
        this.assuntoSelected = [];
        this.e1++;
    }
    onChangeDesejaGerarReuniaoSelect(val) {
        if (val === 'Sim') {
            this.ocorrenciaReuniaoSelected = 1;
        }
        else {
            this.ocorrenciaReuniaoSelected = null;
        }
    }
    get departamentoOptions() {
        return departamentoStore.departamentos;
    }
    get objecaoOptions() {
        if (this.departamentoSelected && this.departamentoSelected.length > 0) {
            return _.filter(this.objecoes, 
            // @ts-ignore
            (item) => item.departamento_id === this.departamentoSelected[1].id);
        }
    }
    get objecaoByAssuntoOptions() {
        if (this.departamentoSelected && this.assuntoSelected) {
            return _.filter(this.objecoes, (item) => 
            // @ts-ignore
            item.departamento_id === this.departamentoSelected[1].id &&
                // @ts-ignore
                this.assuntoSelected[0] === item.assunto);
        }
    }
    get assuntoOptions() {
        return Object.keys(_.groupBy(this.objecaoOptions, 'assunto'));
    }
    get objecoes() {
        return objecaoStore.objecoes;
    }
    get getLabelObservacaoTarefa() {
        if (this.desejaGerarReuniaoSelect) {
            return 'Observações da reunião';
        }
        else {
            return 'Observações da tarefa';
        }
    }
    async adicionarTarefa() {
        let entidadeIsValid = true;
        entidadeIsValid =
            // @ts-ignore
            await this.$refs.searchEntidadeComponent.$refs.observer.validate();
        // @ts-ignore
        const isValid = await this.$refs.observer.validate();
        if (!isValid || !entidadeIsValid) {
            return;
        }
        this.tarefas.push({
            responsavel_id: this.ocorrenciaEntidadeSelected.id,
            responsavel_nome: this.ocorrenciaEntidadeSelected.nome,
            data: this.ocorrenciaTarefaData,
            hora: this.ocorrenciaTarefaHora,
            descricao: this.tarefaDescricao,
            reuniao_tipo_id: this.ocorrenciaReuniaoSelected,
            status_id: 0,
        });
        this.desejaGerarTarefaSelected = null;
        this.ocorrenciaTarefaData = '';
        this.ocorrenciaTarefaHora = '';
        this.tarefaDescricao = null;
        this.ocorrenciaReuniaoSelected = null;
        // @ts-ignore
        await this.$refs.observer.reset();
    }
    getAnexoSize(size) {
        if (size / 1000 < 1000) {
            const value = (size / 1000).toFixed(2);
            return `${value} kB`;
        }
        else if (size / 1000 >= 1000) {
            const value = (size / 1000000).toFixed(2);
            return `${value} MB`;
        }
    }
    resetData() {
        this.objecaoSelected = [];
        this.assuntoSelected = [];
        this.origemSelected = [];
        this.departamentoSelected = [];
        this.ocorrenciaObservacao = '';
        this.ocorrenciaObservacaoTarefa = '';
        this.desejaGerarTarefaSelected = null;
        this.ocorrenciaEntidadeSelected = '';
        this.ocorrenciaTarefaData = '';
        this.ocorrenciaTarefaHora = '';
        this.modoCriarOcorrencia = false;
        this.ocorrenciaReuniaoSelected = null;
        this.tarefas = [];
        this.e1 = 1;
    }
    closeDialog() {
        this.resetData();
        this.$emit('on-cancel');
    }
    async salvarOcorrencia() {
        this.loadingGerandoOcorrencia = true;
        const obj = {
            origem_id: this.origemSelected[1].id,
            departamento_id: this.departamentoSelected[1].id,
            observacao: this.ocorrenciaObservacao,
            objecao_id: null,
            entidade_id: this.propEntidadeId,
            entidade_tipo: this.propEntidadeTipo,
            tarefas: this.tarefas,
        };
        if (this.objecaoSelected.length > 0) {
            obj.objecao_id = this.objecaoSelected[1].id;
        }
        if (this.desejaGerarTarefaSelected === 1) {
            let data_hora_tarefa = this.ocorrenciaTarefaData;
            data_hora_tarefa =
                data_hora_tarefa + 'T' + this.ocorrenciaTarefaHora + ':00-03:00';
            const horario_brasilia = new Date(data_hora_tarefa);
            data_hora_tarefa = horario_brasilia;
        }
        await ocorrenciaStore.createOcorrencia({
            data: obj,
            files: this.ocorrenciaUploadFiles,
        });
        this.resetData();
        this.closeDialog();
        this.loadingGerandoOcorrencia = false;
    }
};
__decorate([
    Prop()
], CriarOcorrenciaComponent.prototype, "propEntidadeId", void 0);
__decorate([
    Prop()
], CriarOcorrenciaComponent.prototype, "propEntidadeTipo", void 0);
__decorate([
    Prop({ type: Array, default: () => [] })
], CriarOcorrenciaComponent.prototype, "propDepartamentoOcorrenciaId", void 0);
__decorate([
    Watch('origemSelected')
], CriarOcorrenciaComponent.prototype, "onchangeOrigemSelecetd", null);
__decorate([
    Watch('objecaoSelected')
], CriarOcorrenciaComponent.prototype, "onChangeObjecaoSelected", null);
__decorate([
    Watch('assuntoSelected')
], CriarOcorrenciaComponent.prototype, "onChangeAssuntoSelected", null);
__decorate([
    Watch('departamentoSelected')
], CriarOcorrenciaComponent.prototype, "onChangeDepartamentoSelected", null);
__decorate([
    Watch('desejaGerarReuniaoSelect')
], CriarOcorrenciaComponent.prototype, "onChangeDesejaGerarReuniaoSelect", null);
CriarOcorrenciaComponent = __decorate([
    Component({
        components: {
            UploadFilesComponent,
            ClickableCard,
            DateFieldComponent,
            SearchEntidadeComponent,
            ValidationObserver,
            ValidationProvider,
        },
    })
], CriarOcorrenciaComponent);
export default CriarOcorrenciaComponent;
